import { API_URL } from '../utils/configs';
import apiClient from './apiClient';
import {
  AppleLoginRequest,
  AppleLoginResponse,
  KakaoLoginRequest,
  UpdateSignupUserInfoRequest,
} from './types/auth.types';
import { BaseResponse } from './types/common.types';

type GoogleLoginRequest = {
  google_access_token: string;
  googleId: string;
  platform: string;
  name?: string;
  email?: string;
};

export const googleLogin = async (data: GoogleLoginRequest) => {
  return apiClient.post(`${API_URL}/api/social-auth/google/login`, data);
};

export const getGoogleUserInfo = async (token: string) => {
  return apiClient.get(
    `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${token}`
  );
};

export const kakaoLogin = async (data: KakaoLoginRequest) => {
  return apiClient.post(`${API_URL}/api/social-auth/kakao/login`, data);
};

export const appleLogin = async (data: AppleLoginRequest) => {
  return apiClient.post<BaseResponse<AppleLoginResponse>>(
    `${API_URL}/api/social-auth/apple/login`,
    data
  );
};

export const updateSignUpUserInfo = async ({
  token,
  data,
}: UpdateSignupUserInfoRequest) => {
  return apiClient.patch(`${API_URL}/api/users/updateUser`, data);
};

export const checkForNickName = async ({
  token,
  data,
}: {
  token: string;
  data: { nickName: string };
}) => {
  return apiClient.patch(`${API_URL}/api/users/checkNickname`, data);
};

export const reissueToken = (refreshToken: string) => {
  return apiClient.post<BaseResponse<{ jwtToken: string; refreshToken: string }>>(
    '/api/users/refresh-token',
    { refreshToken }
  );
};
