import { API_URL } from 'src/utils/configs';
import apiClient from './apiClient';
import { BaseResponse } from './types/common.types';
import {
  ExistingReview,
  ProductReviewResponse,
  ProductReviewSummary,
  RegisterProductReview,
  RegisterProductReviewRequest,
  ReportProductReviewRequest,
  ReviewDeletionInfo,
  SalesData,
  UpdateProductReview,
  UpdateProductReviewRequest,
} from './types/products.types';

export const getProductReviews = async (productId: string) => {
  return apiClient.get<BaseResponse<ProductReviewResponse>>(
    `${API_URL}/api/products/${productId}/reviews`
  );
};

export const getProductReviewsLoggedIn = async (productId: string) => {
  const url = `${API_URL}/api/products/${productId}/reviews/loggedIn`;

  return apiClient.get<BaseResponse<ProductReviewResponse>>(url);
};

export const getProductReviewSummary = async (productId: string) => {
  return apiClient.get<BaseResponse<ProductReviewSummary>>(
    `${API_URL}/api/products/${productId}/review/summary`
  );
};

export const getSalesData = async (productId: string) => {
  const url = `${API_URL}/api/products/${productId}/sales-data/single`;

  return apiClient.get<BaseResponse<SalesData>>(url);
};

export const getExistingReviews = (reviewId: string) => {
  const url = `${API_URL}/api/products/review/${reviewId}`;

  return apiClient.get<BaseResponse<ExistingReview>>(url);
};

export const registerProductReview = async ({
  productId,
  image,
  rating,
  content,
}: RegisterProductReviewRequest) => {
  const formData = new FormData();
  formData.append('rating', rating.toString());
  formData.append('content', content);
  image.forEach((img) => formData.append('review', img));

  const url = `${API_URL}/api/products/${productId}/review`;
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return apiClient.post<BaseResponse<RegisterProductReview>>(url, formData, config);
};

export const updateProductReview = ({
  image,
  rating,
  content,
  reviewId,
  removedImageKeys,
}: UpdateProductReviewRequest) => {
  const url = `${API_URL}/api/products/review/${reviewId}`;

  const formData = new FormData();
  formData.append('newRating', rating.toString());
  formData.append('newContent', content);
  if (removedImageKeys && removedImageKeys.length > 0) {
    removedImageKeys.forEach((key) => formData.append('removedKeys', key));
  } else {
    formData.append('removedKeys', 'null');
  }
  image.forEach((img) => formData.append('review', img));

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return apiClient.patch<BaseResponse<UpdateProductReview>>(url, formData, config);
};

export const reportProductReview = async ({
  reviewId,
  problemType,
  reportText,
}: ReportProductReviewRequest) => {
  const url = `${API_URL}/api/products/review/${reviewId}/report`;
  const data = {
    problemType,
    reportText: problemType === 'other' ? reportText : null,
  };

  return apiClient.post<BaseResponse>(url, data);
};

export const getReviewDeletionInfo = async (reviewId: string) => {
  const url = `${API_URL}/api/products/review/${reviewId}/deletion`;

  return apiClient.get<BaseResponse<ReviewDeletionInfo>>(url);
};
