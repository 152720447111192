import axios from 'axios';
import { PATH } from 'src/constants/_common/path';
import { API_URL } from 'src/utils/configs';
import {
  getAccessToken,
  getRefreshToken,
  removeUserInfoLocalStorage,
  setAccessToken,
  setRefreshToken,
} from 'src/utils/request';
import { reissueToken } from './auth';

const apiClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;
let refreshSubscribers: Array<(accessToken: string) => void> = [];

const onRefreshed = (accessToken: string) => {
  refreshSubscribers.forEach((callback) => callback(accessToken));
  refreshSubscribers = [];
};

const addRefreshSubscriber = (callback: (accessToken: string) => void) => {
  refreshSubscribers.push(callback);
};

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.data?.message === 'jwt expired') {
      if (isRefreshing) {
        return new Promise((resolve) => {
          addRefreshSubscriber((accessToken: string) => {
            originalRequest.headers = {
              ...originalRequest.headers,
              Authorization: `Bearer ${accessToken}`,
            };
            resolve(apiClient(originalRequest));
          });
        });
      }
      isRefreshing = true;

      try {
        const refreshToken = getRefreshToken();
        if (!refreshToken) {
          throw new Error('Refresh token not found');
        }

        const response = await reissueToken(refreshToken);
        const newAccessToken = response.data.data.jwtToken;
        const newRefreshToken = response.data.data.refreshToken;

        setAccessToken(newAccessToken);
        setRefreshToken(newRefreshToken);
        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: `Bearer ${newAccessToken}`,
        };

        onRefreshed(newAccessToken);

        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'reissueToken',
            data: {
              jwtToken: newAccessToken,
              refreshToken: newRefreshToken,
            },
          })
        );

        return apiClient(originalRequest);
      } catch (refreshError) {
        removeUserInfoLocalStorage();
        window.location.href = PATH.LOGIN;

        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
