import { t } from 'i18next';
import { useState } from 'react';
import { ProductReview } from 'src/apis/types/products.types';
import FullScreenImageSlider from 'src/components/common/FullScreenImageSlider';
import MixpanelScreenLogger from 'src/components/Logger/MixpanelScreenLogger';
import { ProductTypes } from 'src/types/product';
import { eventNames } from 'src/utils/mixpanel';
import { getPlatformType } from 'src/utils/platformUtils';
import Header from './Header';
import Images from './Images';
import * as S from './index.styles';
import Reply from './Reply';
import ReviewText from './ReviewText';

type Props = {
  productId: string;
  productName: string;
  productType: ProductTypes;
  brandName: string;
  reviewId: string;
  userProfileImage: string;
  nickName: string;
  reviewAuthorId: string;
  reviewDate: string;
  rating: number;
  reviewText: string;
  images: ProductReview['image'];
  reply: ProductReview['reply'];
};

const ReviewItem = ({
  productId,
  productName,
  productType,
  brandName,
  reviewId,
  userProfileImage,
  nickName,
  reviewAuthorId,
  reviewDate,
  rating,
  reviewText,
  images,
  reply,
}: Props) => {
  const [fullScreenImage, setFullScreenImage] = useState<{
    imageIndex: number;
    images: string[];
  } | null>(null);

  const handleImageClick = (imageIndex: number, images: string[]) => {
    setFullScreenImage({ imageIndex, images });
  };

  const handleCloseFullScreenImage = () => {
    setFullScreenImage(null);
  };

  return (
    <S.Container>
      <Header
        {...{
          productId,
          productName,
          productType,
          brandName,
          reviewId,
          userProfileImage,
          nickName,
          reviewAuthorId,
          reviewDate,
          rating,
        }}
      />

      {images.length > 0 && (
        <Images
          images={images.map((image) => image.url)}
          handleImageClick={handleImageClick}
        />
      )}

      <ReviewText reviewText={reviewText} />

      {reply.map((replyItem) => {
        const isCreator = replyItem.userType === 'creator';
        return (
          <Reply
            key={replyItem.date}
            author={
              isCreator
                ? replyItem.userId.name
                : t('store.productDetailPage.reviewSection.haplAdmin')
            }
            date={replyItem.date}
            content={replyItem.content}
            isCreator={isCreator}
          />
        );
      })}

      {fullScreenImage && (
        <MixpanelScreenLogger
          params={{
            eventName: eventNames.reviewImageFullScreen,
            options: {
              source:
                productType === 'sticker'
                  ? '/storeStickerDetail'
                  : '/storeTemplateDetail',
              productName,
              productType,
              brandName,
              authorId: reviewAuthorId,
              authorNickname: nickName,
              platformType: getPlatformType(),
            },
          }}
        >
          <FullScreenImageSlider
            initialIndex={fullScreenImage.imageIndex}
            images={fullScreenImage.images}
            onClose={handleCloseFullScreenImage}
          />
        </MixpanelScreenLogger>
      )}
    </S.Container>
  );
};

export default ReviewItem;
