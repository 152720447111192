import { useTranslation } from 'react-i18next';
import { PATH } from 'src/constants/_common/path';
import useCurrentUserQuery from 'src/hooks/apis/auth/useCurrentUserQuery';
import useModalManager from 'src/hooks/modal/useModalManager';
import { images } from '../../../../../assets/images/search/images';
import store_images from '../../../../../assets/images/store/images';
import { trackEvent } from '../../../../../hooks/store/useMixpanel';
import useNavigate from '../../../../../hooks/useNavigation';
import { ProductDataProps } from '../../../../../types/search/commonPropsTypes';
import { highlightedText } from '../../../../../utils/highlightedText';
import { eventNames } from '../../../../../utils/mixpanel';
import * as G from '../../ProductCommon.styles';
import * as S from './StickerData.styles';

const StickerData = ({ text, data }: ProductDataProps) => {
  const { data: currentUser } = useCurrentUserQuery();
  const { navigateTo, navigateToStorageOrInternalLink, navigateToAppScreen } =
    useNavigate();
  const { t, i18n } = useTranslation();
  const showUseStorageInAppPopUp = useModalManager('UseStorageInAppPopUp');
  const platform = localStorage.getItem('platform') ?? '';
  const userId = localStorage.getItem('userId') ?? '';

  const platformType = platform === 'ios' || platform === 'android' ? 'app' : 'web';
  const isUser = currentUser ? true : false;

  return (
    <G.MainDiv>
      <G.ProductLayout>
        {data.map((item: any, index: number) => {
          const productId = item?.productId || item?._id;
          const title = i18n.language === 'en' ? item?.engName : item?.korName;
          const brandTitle =
            i18n.language === 'en' ? item?.brand?.nameEN : item?.brand?.name;
          const brandImage =
            item?.brand?.logoImageSignUrl ||
            item?.brand?.logoImage?.location ||
            store_images.defaultProfile;

          return (
            <G.ProductContainer
              key={item._id}
              onClick={() => {
                if (currentUser)
                  trackEvent(eventNames.searchResultItem, currentUser, {
                    categoryName: 'sticker',
                    productName: title,
                    brandName: brandTitle,
                    isMyStorage: item?.isPurchased === true,
                    sequence: index + 1,
                    platformType,
                    isUser,
                  });

                if (item?.isPurchased === true || item?.userId === userId) {
                  if (platformType === 'web') {
                    showUseStorageInAppPopUp();
                  } else {
                    navigateToStorageOrInternalLink({ item, platform });
                  }
                  return;
                }

                if (platform) {
                  const payload = {
                    productId,
                    comingFrom: 'SearchScreen',
                    productType: 'sticker/detail',
                    screen: 'StoreScreen',
                  };
                  navigateToAppScreen(payload);
                } else {
                  navigateTo(`${PATH.STICKER_DETAIL}/${productId}`);
                }
              }}
            >
              {item?.isPurchased === true && (
                <G.FromStorageContainer>
                  <G.FromStorageText>{t('search.resultTab.storage')}</G.FromStorageText>
                </G.FromStorageContainer>
              )}

              <S.StickerThumbnail>
                <S.ThumbnailResize
                  src={item?.sticker?.signUrl || store_images.defaultProfileSquare}
                  alt='thumbnail'
                />
              </S.StickerThumbnail>

              <G.FragTextContainer>
                <G.ProductTitleContainer>
                  <G.ProductTitleContainer>
                    <G.ProductTitle>{highlightedText(title, text)}</G.ProductTitle>
                  </G.ProductTitleContainer>
                  <img src={images.arrowRight} alt='arrow_right' width={8} height={22} />
                </G.ProductTitleContainer>

                <G.CreatorAreaContainer>
                  <G.CreatorImageContainer>
                    <G.CreatorImageResize src={brandImage} alt='creator_image' />
                  </G.CreatorImageContainer>
                  <G.CreatorContainer>
                    <G.CreatorName>{brandTitle}</G.CreatorName>
                  </G.CreatorContainer>
                </G.CreatorAreaContainer>
              </G.FragTextContainer>
            </G.ProductContainer>
          );
        })}
      </G.ProductLayout>
    </G.MainDiv>
  );
};

export default StickerData;
